import React, { useState } from "react";
import { useHistory } from "react-router";
import { analytics } from "~/backend/analytics/analytics";
import { useStopImpersonating } from "~/backend/data-hooks/admin/useStopImpersonating";
import { useLogout } from "~/backend/data-hooks/user/useLogout";
import { useMe } from "~/backend/data-hooks/user/useMe";
import { AnalyticsProps } from "../analytics/AnalyticsProps";
import { LoginFlowDialog } from "../dialogs/login/LoginFlowDialog";
import { UploadTrackDialog } from "../dialogs/upload2/UploadTrackDialog";
import { useFeatureFlag } from "../util/useFeatureFlag";
import { INavAction } from "./INavAction";
import { NavBarBase, NavBarBaseProps } from "./NavBarBase";

interface IProps {
  documentTitle?: string;
  pageActions?: INavAction[];
  // In most cases we will want to show a getting started button when user is logged out
  // BUT in one case (claiming an import) we do not want this extra button which is confusing to user. So hide it.
  hideGettingStartedButton?: boolean;
}

export type NavBarProps = NavBarBaseProps & IProps;

export const NavBar = ({
  pageActions,
  hideGettingStartedButton,
  ...navBarBaseProps
}: NavBarProps) => {
  const { data } = useMe();
  const [loginDialogOpened, updateLoginDialogOpened] = useState(false);
  const [uploadDialogOpened, updateUploadDialogOpened] = useState(false);
  const doLogout = useLogout();
  const stopImpersonating = useStopImpersonating();

  const user = data && data.me ? data.me : undefined;
  const enableGarminLink = useFeatureFlag("garmin");

  let actions = pageActions
    ? [...pageActions]
    : [
        {
          title: "Learn",
          action: "/sailing-data-course/",
          hideInMobileMenu: true,
          hideInWebMenu: true,
        },
        {
          title: "Blog",
          action: "/blog/",
          hideInMobileMenu: true,
          hideInWebMenu: true,
        },
        user
          ? {
              title: "Profile",
              action: "/profile",
              hideInMobileMenu: true,
              hideInWebMenu: true,
            }
          : {
              title: "Pricing",
              action: "/pricing",
              hideInMobileMenu: true,
              hideInWebMenu: true,
            },
        {
          title: "Contact",
          action: "/contact",
          hideInMobileMenu: true,
          hideInWebMenu: true,
        },
      ];

  if (user) {
    // First add regular menu items
    actions.push({ title: "Profile", action: "/profile", hideInNavbar: true });
    actions.push({ title: "Sessions", action: "/", hideInNavbar: true });

    // Add "Stop Impersonating" option if user is being impersonated
    // We add it after the main navigation items but before the other options
    if (user.teleportedFrom) {
      actions.push({
        title: `Stop Impersonating (Return to ${
          user.teleportedFrom.name || "Admin"
        })`,
        action: () => {
          stopImpersonating()
            .then(() => {
              // Optionally show a success message
            })
            .catch((e) => {
              // Handle error
              console.error("Failed to stop impersonating:", e);
            });
        },
        // Show in the profile menu with a distinctive style
        hideInNavbar: true,
        style: "secondary",
      });
    }
    actions.push({
      title: "Upload",
      style: "outlined",
      action: () => {
        updateUploadDialogOpened(true);
      },
      hideInNavbar: actions.length > 1,
    });
    if (enableGarminLink) {
      actions.push({
        title: "Garmin",
        action: "/profile/garmin",
        hideInNavbar: true,
      });
    }
    if (user.isAdmin) {
      actions.push({
        title: "AdminPanel",
        action: "/admin",
        hideInNavbar: true,
      });
    }
    actions.push({
      title: "Log out",
      action: () => {
        analytics.event("logout");
        doLogout();
      },
      hideInNavbar: true,
    });
  } else {
    actions.push({
      title: "Sign In",
      action: () => {
        updateLoginDialogOpened(true);
      },
      hideInNavbar: pageActions && pageActions.length > 0,
    });

    // Fill up the mobile popup with some more links when not logged in.
    actions.push({
      title: "Learn",
      action: "/sailing-data-course/",
      hideInNavbar: true,
    });
    actions.push({
      title: "Blog",
      action: "/blog/",
      hideInNavbar: true,
    });
    actions.push({
      title: "Pricing",
      action: "/pricing",
      hideInNavbar: true,
    });
    actions.push({
      title: "Contact",
      action: "/contact",
      hideInNavbar: true,
    });
  }

  const history = useHistory();
  return (
    <React.Fragment>
      <NavBarBase user={user} actions={actions} {...navBarBaseProps} />
      <AnalyticsProps actionName="navbar">
        <LoginFlowDialog
          open={loginDialogOpened}
          onDismiss={() => updateLoginDialogOpened(false)}
          eventProps={{ actionName: "navbar-login" }}
        />
        <UploadTrackDialog
          open={uploadDialogOpened}
          onClose={() => updateUploadDialogOpened(false)}
          onComplete={(track, sessionId) => {
            updateUploadDialogOpened(false);
            history.push(`/session/${sessionId}`);
          }}
        />
      </AnalyticsProps>
    </React.Fragment>
  );
};
