import React from "react";
import { SeasonalPromoBanner } from "../banner/SeasonalPromoBanner";
import { ElevatingNavBar } from "../navbar/ElevatingMenuBar";
import { NavBar } from "../navbar/NavBar";
import { CSFooter } from "./CSFooter";

interface IProps {
  children?: React.ReactNode;
}

const CSPage = ({ children }: IProps) => {
  return (
    <React.Fragment>
      <ElevatingNavBar banner={<SeasonalPromoBanner />}>
        <NavBar position="static" elevation={0} />
      </ElevatingNavBar>
      {children}
      <CSFooter dark={true} />
    </React.Fragment>
  );
};

export default CSPage;
