import { makeStyles, useScrollTrigger } from "@material-ui/core";
import clsx from "clsx";
import { ReactNode } from "react";

interface IProps {
  banner?: ReactNode;
  className?: string;
  children: ReactNode;
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: "sticky",
    zIndex: 10,
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: "white",
  },
  elevate: {
    boxShadow: "0px 5px 5px -1px rgb(0 0 0 / 20%)",
  },
}));

export const ElevatingNavBar = ({ banner, children, className }: IProps) => {
  const classes = useStyles();
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return (
    <div className={clsx(classes.root, trigger && classes.elevate, className)}>
      {banner}
      {children}
    </div>
  );
};
