import {
  AppBar,
  CircularProgress,
  Container,
  Fade,
  Hidden,
  IconButton,
  makeStyles,
  Popover,
  SwipeableDrawer,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { AppBarProps } from "@material-ui/core/AppBar";
import Close from "@material-ui/icons/Close";
import Menu from "@material-ui/icons/Menu";
import clsx from "clsx";
import { ReactNode, useRef, useState } from "react";
import { UserInfo } from "~/backend/graphql/UserInfo";
import { Logotype } from "../logotype/Logotype";
import { Avatar } from "./Avatar";
import { INavAction } from "./INavAction";
import { NavBarAction } from "./NavBarAction";
import { ProfilePopup } from "./ProfilePopup";
import { ProfileSidebar } from "./ProfileSidebar";

const NavBarHeight = 52;

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
  },
  darkMode: {
    backgroundColor: "#0F2D48",
    color: "#f0f0f0",
    "& $loadingIndicator": {
      color: "#f0f0f0",
    },
  },
  toolbarRoot: {
    height: NavBarHeight,
    minHeight: "unset",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      minHeight: "auto",
    },
  },
  title: {
    // without this the container does not get a width and elipsis do not work
    minWidth: "0%",
    whiteSpace: "nowrap",
    flexGrow: 1,
  },
  loadingIndicator: {
    color: theme.palette.text.primary,
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      marginRight: theme.spacing(1),
    },
  },
  centerElement: {},
  linkContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    flexGrow: 1,
    "& a": {
      marginLeft: theme.spacing(3),
      fontSize: "18px",
      fontWeight: "bold",
      letterSpacing: 0.8,
      textDecoration: "none",
      textTransform: "uppercase",
      color: theme.palette.text.primary,
      "&.active": {
        color: `${theme.palette.primary.main} !important`,
      },
      "&:hover": {
        textDecoration: "none",
      },
    },
    "& .MuiButton-root": {
      marginLeft: theme.spacing(3),
      whiteSpace: "nowrap",
    },
    "& .MuiButton-outlinedPrimary": {
      color: theme.palette.primary.main,
    },
    "& .MuiIconButton-root": {
      // margin for the avatar icon but not for menu icon
      [theme.breakpoints.up("md")]: {
        marginLeft: theme.spacing(3),
        marginRight: -12,
      },
    },
    "& .MuiAvatar-root": {
      height: 42,
      width: 42,
    },
  },
  mobileMenuButton: {
    marginRight: -7,
  },
  profilePopup: {
    marginLeft: -12,
    marginTop: 20,
  },
}));

interface INavBarProps {
  title?: ReactNode;
  centerElement?: ReactNode;
  darkMode?: boolean;
  user?: UserInfo;
  actions?: INavAction[];
  // Shown in the profile sidebar on mobile
  documentTitle?: string;
  showLoadingIndicator?: boolean;
  disableMaxWidth?: boolean;
}

export type NavBarBaseProps = INavBarProps &
  Omit<AppBarProps, "children" | "title">;

export const NavBarBase = ({
  title,
  centerElement,
  actions,
  user,
  darkMode,
  showLoadingIndicator,
  disableMaxWidth,
  documentTitle,
  className,
  ...appBarProps
}: NavBarBaseProps) => {
  const classes = useStyles();
  const [profileOpened, updateProfileOpened] = useState(false);
  const popupAnchor = useRef<HTMLButtonElement | null>(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  actions = actions || [];

  const navBarElements = actions
    .filter((a) => !a.hideInNavbar)
    .map((a, idx) => <NavBarAction {...a} key={idx} />);
  const webMenuActions = actions.filter((a) => !a.hideInWebMenu);
  const mobileMenuActions = actions.filter(
    (a) => !a.hideInMobileMenu && !a.documentRelated
  );
  const mobileDocActions = actions.filter(
    (a) => a.documentRelated && !a.hideInMobileMenu
  );

  return (
    <AppBar
      color="default"
      className={clsx(classes.root, darkMode && classes.darkMode, className)}
      {...appBarProps}
    >
      <Container maxWidth={disableMaxWidth === true ? false : undefined}>
        <Toolbar className={classes.toolbarRoot} disableGutters>
          {!darkMode && <Logotype small={isSmallScreen} hideType={!!title} />}
          {title && <div className={classes.title}>{title}</div>}
          {centerElement && (
            <Hidden smDown={true}>
              <div className={classes.centerElement}>{centerElement}</div>
            </Hidden>
          )}
          <div className={classes.linkContainer}>
            <Fade in={showLoadingIndicator} mountOnEnter unmountOnExit>
              <CircularProgress
                className={classes.loadingIndicator}
                size={isSmallScreen ? 24 : 28}
              />
            </Fade>
            <Hidden smDown={true}>
              {navBarElements}
              {user && (
                <IconButton
                  onClick={() => updateProfileOpened(true)}
                  ref={popupAnchor ?? null}
                >
                  <Avatar user={user} />
                </IconButton>
              )}
            </Hidden>
            <Hidden mdUp={true}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={() => updateProfileOpened((opened) => !opened)}
                size="small"
                className={classes.mobileMenuButton}
              >
                {profileOpened ? <Close /> : <Menu />}
              </IconButton>
            </Hidden>
          </div>
        </Toolbar>
        <Hidden smDown={true}>
          <Popover
            anchorEl={popupAnchor.current}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={profileOpened && !!user}
            onClose={() => updateProfileOpened(false)}
            PaperProps={{ className: classes.profilePopup }}
          >
            <ProfilePopup user={user} actions={webMenuActions} />
          </Popover>
        </Hidden>
        <Hidden mdUp={true}>
          <SwipeableDrawer
            anchor="right"
            open={profileOpened}
            onOpen={() => updateProfileOpened(true)}
            onClose={() => updateProfileOpened(false)}
            disableSwipeToOpen={true}
          >
            <ProfileSidebar
              user={user}
              actions={mobileMenuActions}
              documentTitle={documentTitle}
              documentActions={mobileDocActions}
            />
          </SwipeableDrawer>
        </Hidden>
      </Container>
    </AppBar>
  );
};
